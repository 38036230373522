import React, { useCallback, useEffect, useState } from 'react';
import { loadFull } from "tsparticles"
import Particles from 'react-particles'
import './App.css';

import messages from "./messages.json"

const App = () => {
  const [content, setContent] = useState({
    address: "Baby",
    message: "You are my whole world"
  })
  const particlesInit = useCallback(async (engine: any) => {
    await loadFull(engine)
  }, [])
  const particlesLoaded = useCallback(async (container: any) => {}, [])

  const getMessage = () => {
    setContent({
      address: messages.addresses[sortaRandom(messages.addresses.length)],
      message: messages.messages[sortaRandom(messages.messages.length)]
    })
  }
  const sortaRandom = (length: number) => {
    return Math.floor(Math.random() * length)
  }
  useEffect(() => {
    getMessage()
  }, [])
  return (
    <div className="App">
      <header className="App-header">
        <Particles 
          className = "tsparticles" 
          init ={particlesInit} 
          loaded={particlesLoaded}
          options={{
            fpsLimit: 120,
            particles: {
              color: {
                  value: "#ffffff",
              },
              links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
              },
              move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                      default: "bounce",
                  },
                  random: false,
                  speed: 3,
                  straight: false,
              },
              number: {
                  density: {
                      enable: true,
                      area: 800,
                  },
                  value: 80,
              },
              opacity: {
                  value: 0.5,
              },
              shape: {
                  type: "circle",
              },
              size: {
                  value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
        <div className = "message">
          <h1>{content.address || "Darling"},</h1>
          <p>{content.message || "I love you more and more every single day."}</p>
        </div>
      </header>
    </div>
  )
}

export default App;
